import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { imgPath } from '../../utils/_api-connector';
import { setPageTitle, tagsToPlexure } from '../../utils/_mcd-connector';
import InputSpinner from '../../components/InputSpinner/InputSpinner';
import AddToButton from '../../components/AddToButton/AddToButton';
import ProductMainInfo from '../../components/ProductMainInfo/ProductMainInfo';
import AccordionItem from '../../components/AccordionItem/AccordionItem';
import imagePlaceholder from '../../assets/placeholder.svg';
import scrollToElement from 'scroll-to-element';
import deepEqual from 'deep-equal';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import './_ProductDetail.scss';


const filterProductOptions = (productOptions) => {
  return productOptions.filter((x) => { 
    if(x.type.toLowerCase() !== 'product' && x.type.toLowerCase() !== 'product happy meal') {
      return x; 
    }
    return false;
  }).map(i => { return i.type});
}

const createProductOptions = (productList, optionsIds, optionCategory) => {
  const productCategories = [...new Set(optionCategory)].map(i => { return {category: i, products: []}});

  optionsIds.forEach((i) => { 
    // eslint-disable-next-line
    return productList.map((item) => {
      if(item.id === i.product_id) { 
        productCategories.forEach(entry => {
          if(entry.category === i.type) {
            entry.image = i.type_image;
            entry.products.push(item)
          }
          return false;
        })
      }
    })
  });
  return productCategories;
};

class ProductDetail extends PureComponent {
  state = {
    productDetail: {
      image: '',
      name: '', 
      description: '', 
      options: []
    },
    productOptions: [],
    productExtras: [],
    isSausesVisible: true,
    spinnerValue: 1,
    isBtnDisabled: true,
    dataLoaded: false
  }
  componentDidMount() {
    let pageTitle  = this.props.products.isLoaded ? this.state.productDetail.name : 'Wishlist';
    setPageTitle(pageTitle);
    if(this.state.dataLoaded) {
      this.setState({
        isBtnDisabled: this.state.productOptions.length > 0 || this.state.productDetail.options.length > 1 || this.state.productExtras.length > 0
      })
    }
    if(this.props.location.state) {
      const { amount, selectedOptions } = this.props.location.state;
      this.setState({
        spinnerValue: amount
      }) 
      this.checkSelectedOptions(selectedOptions)
    }

    window.dataLayer = window.dataLayer || [];

    let { category, name } = this.state.productDetail;
    category.toLocaleLowerCase().replaceAll(' ', '-');
    name.toLocaleLowerCase().replaceAll(' ', '-');

    window.dataLayer.push({
      event: 'page_view',
      eventProperties: {
        page_virtual_path: `/wishlist/${category}/${name}`,
        page_type: 'wishlist'
      }
    });
  }

  checkSelectedOptions = (selectedOptions) => {
    selectedOptions.forEach(i => {
      let selectedInput = document.getElementById(i.id);
      if(selectedInput && !selectedInput.dataset.key) {
        this.handleInputChange(selectedInput, true);
      } else if(selectedInput && selectedInput.dataset.key) {
        this.updateProductOptions(selectedInput, true)
      }
    })
  }

  static getDerivedStateFromProps(nextProps, prevState){
    if(nextProps.products.isLoaded !== prevState.dataLoaded) {
    const productId = +new URLSearchParams(nextProps.location.search).get('productId');
    let productOptions = [];
    let productExtras = [];

    const createExtras = (extrasArray) => {
      extrasArray.forEach((i) => { 
        return nextProps.products.list.map((item) => {
          if(i === item.id) {
            productExtras.push(item);
          }
          return false;
        })
      })
    }

    const productDetail = nextProps.products.list.concat(nextProps.menus.list).filter(el => {
      if(el.id === productId) {
        const optionsIds = el.options[0].items;
        const extrasId = el.extras;
        if(optionsIds) {
          // eslint-disable-next-line
          const optionCategory = filterProductOptions(optionsIds);
          productOptions = createProductOptions(nextProps.products.list, optionsIds, optionCategory);
        }

        if(extrasId && extrasId.length > 0) {
          createExtras(extrasId)
        }

        return el;
      }
      return false;
    });
      return { productDetail: productDetail[0], productOptions: productOptions, productExtras, dataLoaded: nextProps.products.isLoaded}
    }
    return null;
  }

  updateProductOptions = (e, trigger) => {
    const target = !trigger ? e.target : e,
          targetKey = +target.dataset.key;
    const { productDetail } = this.state,
          optionCategory = filterProductOptions(productDetail.options[targetKey].items),
          productOptions = createProductOptions(this.props.products.list, productDetail.options[targetKey].items, optionCategory);
    this.setState(prevState => ({
      productOptions: productOptions
    }), () => {
      document.querySelectorAll('.option-category').forEach(i => {
        if(!i.querySelector('input:checked')) {
          i.classList.remove('validated');
        }
      });
    })
    this.scrollToNextCategory(target, trigger)
  } 

  handlePlus = () => {
    if(this.state.spinnerValue < 9) {
      this.setState(prevState => ({
        spinnerValue: prevState.spinnerValue + 1
      }))
    }
  }

  handleMinus = () => {
    if(this.state.spinnerValue > 1) {
      this.setState(prevState => ({
        spinnerValue: prevState.spinnerValue - 1 
      }))
    }
  }

  scrollToNextCategory = (target, trigger) => {
    target.closest('.option-category').classList.remove('error')
    target.closest('.option-category').classList.add('validated');
    target.closest('.option-category').querySelector('.accordion-item').classList.remove('open');
    if(target.closest('.option-category').nextSibling && !trigger) {
      setTimeout(() => {
        let nextSibling = target.closest('.option-category').nextSibling.querySelector('.accordion-item');
        nextSibling.classList.add('open');
        scrollToElement(nextSibling, {
          duration: 500
        });
      }, 0)
    }
    setTimeout(() => {
      this.checkButtonState();
    })
  }

  checkButtonState = () => {
    let productOptionsLength = document.querySelectorAll('.option-category').length;
    let productOptionsSelected = document.querySelectorAll('.option-category.validated').length;
    if(productOptionsLength === productOptionsSelected) {
      this.setState({
        isBtnDisabled: false
      })
    } else {
      this.setState({
        isBtnDisabled: true
      })
    }
  }

  handleInputChange = (e, trigger) => {
    let target = !trigger ? e.target : e;
    this.scrollToNextCategory(target, trigger);

    // open saus options if fries is selected
    if(target.id.toLowerCase() === 'side29' || target.id.toLowerCase() === 'side10470' || target.id.toLowerCase() === 'side10471') {
      this.setState({
        isSausesVisible: true
      }, () => {
        //add validation for sauses for edit state
        if(document.querySelector('.option-category.sauses').querySelector('input:checked')) {
          document.querySelector('.option-category.sauses').classList.remove('error')
          document.querySelector('.option-category.sauses').classList.add('validated');
        }
      })
    } else if(target.id.toLowerCase() === 'side10228' || target.id.toLowerCase() === 'side12503') {
      this.setState({
        isSausesVisible: false
      })
    }
  }

  handleSubmit = (e) => {
    e.preventDefault();
    let { productOptions, productDetail, productExtras } = this.state;
    let addToWishlist = () => {
      let options = [];
      document.querySelectorAll('.option-category').forEach(i => {
        const itemName = i.querySelector('input[type="radio"]:checked + label').innerHTML.replace(/&amp;/g, '&');
        const item = {
          name: i.classList.contains('size') ? `${this.state.productDetail.name} - ${itemName}` :  itemName,
          id: i.querySelector('input[type="radio"]:checked').id
        }
        options.push(item)
      })
      const productItem = {
        id: productDetail.id,
        name: productDetail.name,
        image: productDetail.image,
        amount: this.state.spinnerValue,
        options: options
      }
      //add products to localStorage
      const isProductEdit = !!new URLSearchParams(this.props.location.search).get('edit'),
            itemOrder = +new URLSearchParams(this.props.location.search).get('order');
      let localStorageProducts = localStorage.getItem('orderItems');
      const updateOrderItems = () => {
        const orderItems = JSON.parse(localStorageProducts);
        let isProductExist = false;
        orderItems.forEach((item, i) => {
          if(item.id === productItem.id && deepEqual(item.options, productItem.options) && !isProductEdit) {
            item.amount += productItem.amount;
            isProductExist = true;
          } else if(item.id === productItem.id && isProductEdit && itemOrder === i && !deepEqual(item.options, productItem.options)) {
            item.options = productItem.options;
            item.amount = productItem.amount;
            isProductExist = true;
          } else if(item.id === productItem.id && isProductEdit && itemOrder !== i && deepEqual(item.options, productItem.options)) {
            item.amount += productItem.amount;
            orderItems.splice(itemOrder, 1)
            isProductExist = true;
          } else if(item.id === productItem.id && isProductEdit && itemOrder === i && deepEqual(item.options, productItem.options) && item.amount !== productItem.amount) {
            item.amount = productItem.amount;
            isProductExist = true;
          } else if(item.id === productItem.id && isProductEdit && itemOrder === i && deepEqual(item.options, productItem.options)){
            isProductExist = true;
          }
        })
        let newLocalStorageProducts = isProductExist ? orderItems : [...orderItems, productItem];
        localStorage.setItem('orderItems', JSON.stringify(newLocalStorageProducts));
      }

      localStorageProducts ? updateOrderItems() : localStorage.setItem('orderItems', JSON.stringify([productItem]));
      if(!isProductEdit) {
        let productImage = document.createElement('img');
        productImage.src = document.querySelector('.product-detail-img img').src;
        productImage.classList.add('fake-photo');
        document.body.append(productImage)
      }

      window.dataLayer = window.dataLayer || [];
      const variants = {};

      for (let o = 0; o < productItem.options.length; o++) {
        const index = !o ? '' : o + 1;
        const { name } = productItem.options[o];
        variants[`item_variant${index}`] = name;
      }

      window.dataLayer.push({ ecommerce: null });
      
      window.dataLayer.push({
        event: 'add_to_cart',
        ecommerce: {
          currency: 'EUR',
          value: 0.00,

          items: [{
            item_id: `${productItem.id}`,
            item_name: productItem.name,
            item_category: this.state.productDetail.category,
            ...variants,
            price: 0.00,
            quantity: `${productItem.amount}`
          }]
        }
      });

      tagsToPlexure(['McDNL_MDB_']);
      this.props.history.goBack();
    }
    if(productOptions.length > 0 || productDetail.options.length > 1 || productExtras.length > 0) {
      let productOptionsLength = document.querySelectorAll('.option-category').length; 
      let productOptionsSelectedLength = document.querySelectorAll('.option-category.validated').length;
      if(productOptionsLength === productOptionsSelectedLength) {
        addToWishlist();
      } else {
        let productOptionsNotSelected = document.querySelectorAll('.option-category:not(.validated)');
        productOptionsNotSelected.forEach(i => {
          i.classList.add('error', 'animated');
          i.addEventListener('animationend', () => {
            i.classList.remove('animated');
          })
        })
        scrollToElement(document.querySelector('.option-category.error'), {
          duration: 500
        });
      }
    } else {
      addToWishlist();
    }
  }
  
  renderTitle = (title, pic) => { 
    let imagePath = '';
    switch (pic) {
      case 'size' :
            imagePath = this.state.productDetail.image;
          break;
      default: 
            imagePath = pic;
    } 
    let img = imagePath === '' ? imagePlaceholder : imgPath(imagePath);

    return (
      <div className="option-title">
        <picture>
          <LazyLoadImage effect="opacity" src={img} alt=""/>
        </picture>
        <div className="option-name">
          Kies je {title}
          <div className="option-note">Verplicht</div>
        </div>
      </div>
    )
  }

  renderOptionItem = (category, name, id, key) => {
    let isChecked = false,
        itemId = `${category.toLowerCase()}${id}`;
    if(this.props.location.state) {
      this.props.location.state.selectedOptions.forEach(i => {
        if(i.id === itemId) {
          isChecked = true;
        }
      })
    }
    const onInputChange = typeof key === 'undefined' ? this.handleInputChange : this.updateProductOptions;
    return (
      <div className="option-item" key={id}>
        <input type="radio" name={category.toLowerCase()} id={itemId} defaultChecked={isChecked} onChange={onInputChange} data-key={key}/>
        <label htmlFor={`${category.toLowerCase()}${id}`}>{name}</label>
      </div>
    )
  }

  render() {
    const { productOptions, productExtras } = this.state;
    const { productDetail } = this.state;
    
    return (
      <>
        {
        this.state.dataLoaded &&
        <form className="product-detail" onSubmit={this.handleSubmit}>
          <ProductMainInfo name={productDetail.name}  image={productDetail.image} description={productDetail.description} />
          {/* for products with size */}
          {
            productOptions.length === 0 && productDetail.options.length > 1 &&
            <div className="product-options">
            <h3>Maak je keuze</h3>
              {
              <div className="option-category">
                <AccordionItem title={this.renderTitle('formaat', 'size')}>
                  { productDetail.options.map(i => {
                    return (
                      this.renderOptionItem('size', i.name, i.id)
                    )
                  })}
                </AccordionItem>
              </div>
              }
            </div>
          }
          {/* for products with options */}
          {productOptions.length > 0 &&
            <div className="product-options">
              <h3>Maak je keuze</h3>
              {
                productDetail.options.length > 1 &&
              <div className="option-category size">
                <AccordionItem title={this.renderTitle('formaat', 'size')}>
                  { productDetail.options.map((i, key) => {
                    return (
                      this.renderOptionItem('size', i.name, i.id, key)
                    )
                  })}
                </AccordionItem>
              </div>
              }
              {productOptions.length > 0 && 
                productOptions.map((item, key) => {
                  let { category, image } = item;
                  if(category !== 'Saus') {
                    return (
                      <div className="option-category" key={key}>
                        <AccordionItem title={this.renderTitle(category, image)}>
                          {
                            item.products.map((item) => {
                              return (
                                this.renderOptionItem(category, item.name, item.id)
                                )
                            })
                          }
                        </AccordionItem>
                      </div>
                    )
                  } else if(this.state.isSausesVisible){
                    return (
                      <div className="option-category sauses" key={key}>
                        <AccordionItem title={this.renderTitle(category, image)}>
                          {
                            item.products.map((item) => {
                              return (
                                this.renderOptionItem(category, item.name, item.id)
                                )
                            })
                          }
                        </AccordionItem>
                      </div>
                    )
                  }
                  return false;
                })
              }
            </div>
          }

          {/* for products with extras */}
          {
            productExtras.length > 0 && 
            <div className="product-options">
              <h3>Maak je keuze</h3>
              {
              <div className="option-category">
                <AccordionItem title={this.renderTitle('saus')}>
                  { productExtras.map(i => {
                    return (
                      this.renderOptionItem(i.category, i.name, i.id)
                    )
                  })}
                </AccordionItem>
              </div>
              }
            </div>
          }
          <InputSpinner handleMinus={this.handleMinus} handlePlus={this.handlePlus} spinnerValue={this.state.spinnerValue} /> 
          <AddToButton amount={this.state.spinnerValue} disabled={this.state.isBtnDisabled} />
        </form>
        }
      </>
    );
  }
}


const mapStateToProps = (state) => {
  return { 
    products: state.products,
    menus: state.menus
  };
}

export default connect(mapStateToProps)(ProductDetail);
