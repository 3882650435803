import React from 'react';
import './_AddToButton.scss';

const AddToButton = props => {
  return (
    <div className="add-button-wrapper">
      <button className={`btn btn-yellow ${props.disabled ? 'disabled' : ''}`} onClick={props.handleClick}>Toevoegen aan Wishlist <span className="add-amount">{props.amount}</span></button>
    </div>
  );
}

export default AddToButton;
