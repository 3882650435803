import React from "react";
import WishlistItem from '../../components/WishlistItem/WishlistItem';
import RemoveModal from '../../components/WishlistItem/RemoveModal';
import Remove from '../../assets/remove.svg';
import Share from '../../assets/share.svg';
import { setPageTitle, tagsToPlexure } from '../../utils/_mcd-connector';
import { Link } from 'react-router-dom';
import './_Wishlist.scss';

class Wishlist extends React.PureComponent {
  state = {
    orderItems: [],
    spinnerValue: 1,
    openModal: false
  }
  componentDidMount() {
    this.setState({
      orderItems: JSON.parse(localStorage.getItem('orderItems'))
    })
    setPageTitle('Wishlist');

    window.dataLayer = window.dataLayer || [];

    window.dataLayer.push({
      event: 'page_view',
      eventProperties: {
        page_virtual_path: '/wishlist/bestelling',
        page_type: 'wishlist'
      }
    });
  }
  handleCloseModal = (e, spinnerValue) => {
    let targetId = +e.target.closest('.wishlist-product').dataset.index;
    const orderItems = JSON.parse(localStorage.getItem('orderItems'));
    orderItems.forEach((item, i) => {
      if(i === targetId) {
        item.amount = spinnerValue;
      }
    })
    localStorage.setItem('orderItems', JSON.stringify(orderItems))
    this.setState({
      orderItems
    })
  }
  removeAllProducts = () => {
    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push({ ecommerce: null });

    const orderItems = JSON.parse(localStorage.getItem('orderItems'));

    const items = orderItems.map((item) => {
      const variants = {};

      for (let o = 0; o < item.options.length; o++) {
        const index = !o ? '' : o + 1;
        const { name } = item.options[o];
        variants[`item_variant${index}`] = name;
      }

      return {
        item_id: `${item.id}`,
        item_name: item.name,
        ...variants,
        price: 0.00,
        quantity: item.amount
      };
    });

    window.dataLayer.push({
      event: 'remove_from_cart',
      ecommerce: {
        currency: 'EUR',
        value: 0.00,
        items
      }
    });

    localStorage.removeItem('orderItems');
    this.setState({
      orderItems: []
    })
    tagsToPlexure(['McDNL_MDB_'])
    this.props.history.push('/')
  }
  removeProduct = (e) => {
    window.dataLayer = window.dataLayer || [];

    let targetId = +e.target.closest('.wishlist-product').dataset.index;
    const orderItems = JSON.parse(localStorage.getItem('orderItems'));
    orderItems.forEach((item, i) => {
      if(i === targetId) {
        const variants = {};
        orderItems.splice(i, 1);
        window.dataLayer.push({ ecommerce: null });

        for (let o = 0; o < item.options.length; o++) {
          const index = !o ? '' : o + 1;
          const { name } = item.options[o];
          variants[`item_variant${index}`] = name;
        }

        window.dataLayer.push({
          event: 'remove_from_cart',
          ecommerce: {
            currency: 'EUR',
            value: 0.00,
            items: [{
              item_id: `${item.id}`,
              item_name: item.name,
              ...variants,
              price: 0.00,
              quantity: item.amount
            }]
          }
        });
      }
    })

    localStorage.setItem('orderItems', JSON.stringify(orderItems))
    this.setState({
      orderItems
    }, () => {
      if(this.state.orderItems.length < 1) {
        this.props.history.push('/')
      }
    })
  }
  openRemoveModal = () => {
    window.dataLayer = window.dataLayer || [];

    window.dataLayer.push({
      event: 'button_click',
      eventAction: 'Verwijder Wishlist',
      eventLabel: '/wishlist/bestelling'
    });

    this.setState({
      openModal: true
    })
  }
  closeRemoveModal = () => {
    window.dataLayer = window.dataLayer || [];

    window.dataLayer.push({
      event: 'button_click',
      eventAction: 'Annuleren',
      eventLabel: '/wishlist/bestelling'
    });

    this.setState({
      openModal: false
    })
  }
  shareLink = () => {
    const orderItems = this.state.orderItems.map(item => {
      let decodedItem = {
        id: item.id,
        name: item.name,
        image: item.image,
        amount: item.amount,
        options: item.options.map(option => {
          return option.name
        })
      };

      return decodedItem;
    });
    return orderItems;
  }
  shareWishlist = () => {
    window.dataLayer = window.dataLayer || [];
    tagsToPlexure(['McDNL_MDB_']);

    window.dataLayer.push({
      event: 'button_click',
      eventAction: 'Deel Wishlist',
      eventLabel: '/wishlist/bestelling'
    });
  }
  render() {
    const { orderItems } = this.state;
    return (
      <section className="wishlist">
        <div className="wishlist-head">
          <h2>Jouw Wishlist</h2>
          <p>Hier staat jouw bestelling. Is ‘ie compleet? Dan ben jij bestel ready! Geef ‘m door in de McDrive of het restaurant en pik jouw bestelling zo snel mogelijk op.</p>
        </div>
        { orderItems && orderItems.length > 0 ?
          <>
          <div className="wishlist-tooltip">
            <div className="tooltip-icon"></div>
            <div className="tooltip-copy">
              Is je bestelling compleet? <br/>
              Lees je Wishlist voor in de McDrive of het restaurant.
            </div>
          </div>
          <ul className="wishlist-products-list">
             { orderItems.map((i, key) => {
                return (
                  <WishlistItem 
                    key={key}
                    index={key}
                    id={i.id}
                    orderNum={key}
                    name = {i.name}
                    image = {i.image}
                    amount = {i.amount}
                    options = {i.options}
                    handleRemoveProduct = {this.removeProduct}
                    closeModal={this.handleCloseModal}
                  />
                )
              })
             }
          </ul>
          <div className="wishlist-actions">
            <a className="actions-item" href={`${window.location.origin}/?openOutsideMcd=true&items=${btoa(JSON.stringify(this.shareLink()))}&#/share`} onClick={this.shareWishlist}>
              <img src={Share} alt="Share"/>
              Deel Wishlist
            </a>
            <div className="actions-item"onClick={this.openRemoveModal}>
              <img src={Remove} alt="Remove"/>
              Verwijder Wishlist
            </div>
          </div>
          </>
          :
          <div className="wishlist-placeholder">
            Je hebt momenteel geen producten in je wishlist
          </div>
        }
        <div className="wishlist-info">
          <h2>Meer voedingsinformatie?</h2>
          <div className="info-content">
            <a href="https://www.mcdonalds.com/nl/nl-nl/full-menu.html?openOutsideMcd=true" className="internal-link" target="_blank" rel="noopener noreferrer"> 
              Bekijk alle voedingsinformatie in ons assortiment
            </a>
            <hr/>
            <p>Heb je speciale verzoeken of dieetwensen? Vermeld deze dan tijdens het bestellen.</p>
          </div>
        </div>
        <div className="wishlist-info">
          <h2>Vragen?</h2>
          <div className="info-content">
            <Link to={{pathname: "/tutorial", state: {faq: true}}} className="internal-link">Voor meer informatie bekijk de veelgestelde vragen</Link>
          </div>
        </div>
        {this.state.openModal && <RemoveModal removeWishlist={this.removeAllProducts} closeModal={this.closeRemoveModal} />}
      </section>
    );
  }
}

export default Wishlist;