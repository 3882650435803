import React from 'react';
import { Link } from 'react-router-dom';
import { tagsToPlexure } from '../../utils/_mcd-connector';
import Bag from '../../assets/bag.svg';
import './_BackToWishlist.scss';

const BackToWishlist = () => {
  let amount = () => {
    return JSON.parse(localStorage.getItem('orderItems')).reduce((a, b) => a + (b.amount || 0), 0);
  }

  const onLinkClick = () => {
    tagsToPlexure(['McDNL_MDB_']);
    window.dataLayer = window.dataLayer || [];

    window.dataLayer.push({
      event: 'button_click',
      eventAction: 'Bekijk jouw Wishlist',
      eventLabel: '/wishlist/bestelling'
    });
  };

  return (
    <Link to="/wishlist" className="back-to-whishlist btn btn-yellow" onClick={onLinkClick}>
      Bekijk jouw Wishlist
      <div className="whishlist-pocket">
        <span className="amount">{amount()}</span>
        <img src={Bag} alt="mcd-pocket"/>
      </div>
    </Link>
  );
}

export default BackToWishlist;
