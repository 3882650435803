import axios from 'axios';
import imagePlaceholder from '../assets/placeholder.svg';

const ENV_SUFFIX = process.env.REACT_APP_ENV === 'production' ? '' : 'Stage';

export const getMenus = () =>  {
  return axios({
    method: 'get',
    url: `https://europe-west1-mcdonald-s-fc045.cloudfunctions.net/getMenu${ENV_SUFFIX}`
  });
}

export const getProducts = () =>  {
  return axios({
    method: 'get',
    url: `https://europe-west1-mcdonald-s-fc045.cloudfunctions.net/getProducts${ENV_SUFFIX}`
  });
}

export const getCategories = () =>  {
  return axios({
    method: 'get',
    url: `https://europe-west1-mcdonald-s-fc045.cloudfunctions.net/getCategories${ENV_SUFFIX}`
  });
}

export const imgPath = (image) => {
  if(image) {
    return `https://images.mcdmobile.nl/mcdmobile/${image}`;
  } else {
    return imagePlaceholder;
  }
}

export const setPageTitle = (data) => {
  let name = data.toLowerCase(), 
    pageTitle = name.charAt(0).toUpperCase() + name.substring(1);
  document.title = pageTitle;
}