import React from "react";

const Spinner = props => {
  return (
    <div className="wishlist-amount">
      <div className="wishlist-amount-wrapper">
        <div className="amount-spinner">
          <div className="amount-spinner-btn" onClick={props.handleMinus}>-</div>
          <input type="text" readOnly value={props.spinnerValue} />
          <div className="amount-spinner-btn" onClick={props.handlePlus}>+</div>
        </div>
        <div className="amount-submit btn btn-yellow" onClick={props.handleSubmit}>
          Opslaan
        </div>
      </div>
    </div>
  );
}

export default Spinner;