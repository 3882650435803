import React from 'react';
import './_InputSpinner.scss';

const InputSpinner = props => {
  return (
    <div className="input-spinner">
      <div className="spinner-title">Aantal</div>
      <div className="spinner-wrapper">
        <div className="spinner-trigger" onClick={props.handleMinus}>-</div>
        <input type="text" value={props.spinnerValue} readOnly />
        <div className="spinner-trigger" onClick={props.handlePlus}>+</div>
      </div>
    </div>
  );
}

export default InputSpinner;
