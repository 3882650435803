import React from 'react';
import { connect } from 'react-redux';
import ProductItem from '../../components/ProductItem/ProductItem';
import CategoryDescription from '../../components/CategoryDescription/CategoryDescription';
import BackToWishlist from '../../components/BackToWishlist/BackToWishlist';
import './_ProductsList.scss';

class ProductsList extends React.PureComponent {
  constructor() {
    super();
    this.state = {
      pageName: '',
      productsList: []
    }
  }

  componentDidMount() {
    if(document.querySelector('.fake-photo')) {
      let fakePhoto = document.querySelector('.fake-photo');
      fakePhoto.classList.add('animated');
      
      fakePhoto.addEventListener('animationend', () => {
        fakePhoto.remove()
      })

      document.querySelector('.whishlist-pocket').classList.add('animated');
    }
  }

  static getDerivedStateFromProps(nextProps, prevState){
    const categoryId = +new URLSearchParams(nextProps.location.search).get('category');
    const menusList = nextProps.menus.list.filter(el => {
      if(el.category_id === categoryId) {
        return el;
      }
      return false;
    })
    const productsList = nextProps.products.list.filter(el => {
      if(el.category_id === categoryId && el.standalone_available & el.available_wishlist) {
        return el;
      }
      return false;
    });
    const productsListFiltered = categoryId === 9 ? menusList.concat(productsList) : productsList.concat(menusList);
    return { productsList: productsListFiltered };
 }

  render() {
    const categoryId = +new URLSearchParams(this.props.location.search).get('category'),
          { productsList } = this.state,
          wishlistAmount = localStorage.getItem('orderItems') && JSON.parse(localStorage.getItem('orderItems')).length;
    return (
      <div className="products-section">
        <>
        <CategoryDescription categoryId={categoryId} setPageName={(name) => this.setState({ pageName: name })} />
        {
          productsList.length > 0 ?
          <ul className="products-list">
            {productsList.map((el, item) => {
              return (
                <ProductItem 
                  key={item}
                  id={el.id}
                  name={el.name}
                  image={el.image}
                  pageName={this.state.pageName}
                />)
            })}
          </ul>
          :
          <div className="loader"></div>
        }
        {wishlistAmount > 0 && <BackToWishlist /> }
        </>
      </div>
    );
  }
}


const mapStateToProps = (state) => {
  return { 
    products: state.products,
    menus: state.menus
  };
}

export default connect(mapStateToProps)(ProductsList);
