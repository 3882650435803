import React, { Component } from 'react';
import { connect } from 'react-redux';
import { setPageTitle } from '../../utils/_mcd-connector';
import './_CategoryDescription.scss';


class CategoryDescription extends Component {
  constructor() {
    super();
    this.state = {
      isLoaded: false,
      categoryData: {
        name: '',
        additional_info: ''
      }
    }
  }

  static getDerivedStateFromProps(nextProps, prevState){
    const categoryId = nextProps.categoryId;
    const categoryData = nextProps.categories.list.filter(el => {
      if(el.id === categoryId) {
        return el;
      } else {
        return null;
      }
    })
    return { categoryData: categoryData[0], isLoaded: nextProps.categories.isLoaded};
 }

 componentDidMount() {
  let name = this.state.isLoaded ? this.state.categoryData.name : 'Wishlist';
  setPageTitle(name);

  if (this.state.isLoaded) {
    window.dataLayer = window.dataLayer || [];

    this.props.setPageName(this.state.categoryData.name);

    window.dataLayer.push({
      event: 'page_view',
      eventProperties: {
        page_virtual_path: `/wishlist/${this.state.categoryData.name}`,
        page_type: 'wishlist'
      }
    });
  }
 }

  render() {
    return (
      <>
        { this.state.isLoaded && <div className="category-description">
          <h2>{this.state.categoryData.name}</h2>
          <p>{this.state.categoryData.additional_info}</p>
        </div>}
      </>
    );
  }
}


const mapStateToProps = (state) => {
  return { 
    categories: state.categories
  };
}

export default connect(mapStateToProps)(CategoryDescription);
