import React, { Fragment } from "react";
import { Link } from 'react-router-dom';
import Spinner from './Spinner';
import { imgPath } from '../../utils/_api-connector';
import Edit from '../../assets/edit.svg';
import Remove from '../../assets/remove.svg';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import './_WishlistItem.scss';

class WishlistItem extends React.PureComponent {
  state = {
    openModal: false,
    spinnerValue: this.props.amount
  }

  handleOpenModal = () => {
    this.setState({
      openModal: true
    })
  }

  handleCloseModal = (e) => {
    this.setState({
      openModal: false
    })
    this.props.closeModal(e, this.state.spinnerValue)
  }
  
  handlePlus = () => {
    if(this.state.spinnerValue < 9) {
      this.setState(prevState => ({
        spinnerValue: prevState.spinnerValue + 1
      }))
    }
  }
  handleMinus = () => {
    if(this.state.spinnerValue > 1) {
      this.setState(prevState => ({
        spinnerValue: prevState.spinnerValue - 1 
      }))
    }
  }

  onEditClick = () => {
    window.dataLayer = window.dataLayer || [];
    const name = this.props.name.toLocaleLowerCase().replaceAll(' ', '-');

    window.dataLayer.push({
      event: 'button_click',
      eventAction: 'edit item',
      eventLabel: `/wishlist/${name}`
    });
  };

  render() {
    return (
      <li className="wishlist-product" data-index={this.props.index}>
        <picture className="product-image">
          <LazyLoadImage effect="opacity" src={imgPath(this.props.image)} alt={this.props.name} />
        </picture>
        <div className="product-info">
          <div className="product-name">
            {this.props.name}
          </div>
          { this.props.options.length > 0 && 
            <div className="product-options"> 
              { this.props.options.map((option, i) => {
                if(i !== this.props.options.length - 1) {
                  return (
                    <Fragment key={`${i}`}>
                      {option.name}, <br/>
                    </Fragment>
                  )
                } else {
                  return (
                    <Fragment key={`${i}`}>
                      {option.name}
                    </Fragment>
                  )
                }
              }) }
            </div>
          }
        </div>
        <div className="product-actions">
          <div className="action-item product-amount" onClick={this.handleOpenModal}>{this.props.amount}</div>
          {this.props.options.length > 0 && <Link onClick={this.onEditClick} to={{pathname: `/product-detail`, search: `?productId=${this.props.id}&edit=true&order=${this.props.orderNum}`,state: { selectedOptions: this.props.options, amount: this.props.amount}}} className="action-item"><img src={Edit} alt="edit"/></Link>}
          <div className="action-item" onClick={this.props.handleRemoveProduct}><img src={Remove} alt="remove"/></div>
        </div>
        {this.state.openModal && <Spinner handlePlus={this.handlePlus} handleMinus={this.handleMinus} spinnerValue={this.state.spinnerValue} handleSubmit={this.handleCloseModal} /> }
      </li>
    );
  }
}

export default WishlistItem;