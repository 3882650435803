
import { 
  GET_CATEGORIES,
  GET_MENUS,
  GET_PRODUCTS
} from './actions'

const initialState = {
  categories: {
    list: [],
    isLoaded: false
  },
  menus: {
    list: []
  },
  products: {
    list: [],
    isLoaded: false
  },
  wishlist: {
    list: [],
    isLoaded: false
  }
};

function appReducer(state = initialState, action) {
  switch (action.type) {
    case GET_CATEGORIES:
      return Object.assign({}, state, {
        categories: {
          list: action.data,
          isLoaded: true
        }
      })
    case GET_MENUS:
      return Object.assign({}, state, {
        menus: {
          list: action.data
        }
      })
    case GET_PRODUCTS:
      return Object.assign({}, state, {
        products: {
          list: action.data,
          isLoaded: true
        }
      })
    default:
      return state
  }
}

export default appReducer;
