import React, { useEffect, useState } from 'react';
import jsPDF from 'jspdf';
import html2canvas from 'html2canvas';
import { imgPath } from '../../utils/_api-connector';
import Logo from '../../assets/logo.png';
import moment from 'moment';
import 'moment/locale/nl';
import './_Share.scss';

export default function Share() {
  //set products list
  const url = new URLSearchParams(window.location.href).get('items');
  const [stateProducts] = useState(url ? JSON.parse(atob(url)): []);
  const [fileIsDownloaded, setFileIsDownloaded] = useState(false);
  const [imagesLoaded, setImagesLoaded] = useState(0);

  //set current date
  moment.locale('nl');
  const date = moment(new Date()).format('DD MMMM YYYY'),
        titleDate = moment(new Date()).format('DD.MM.YYYY');

  function createPDF() {
    let downloadMarkup = document.querySelector('.wishlist-pdf');
    html2canvas(downloadMarkup, {useCORS: true}).then(canvas => {
      const doc = new jsPDF('p', 'mm', [canvas.width * 0.2645833333, canvas.height * 0.2645833333]),
            imgData = canvas.toDataURL('image/png'); 
      doc.addImage(imgData, 'PNG', 0, 0, doc.internal.pageSize.getWidth(), doc.internal.pageSize.getHeight(), undefined, 'FAST');
      doc.save(`Wishlist_${titleDate}.pdf`, { returnPromise:true }).then(() => {
        downloadMarkup.remove();
        setFileIsDownloaded(true);
      })
    });
  }
  useEffect(() => {
    if(stateProducts.length > 0 && stateProducts.length === imagesLoaded) {
      createPDF();
    }
    // eslint-disable-next-line
  }, [stateProducts, imagesLoaded]);
  return (
    <section className={`share${fileIsDownloaded ? ' black' : ''}`}>
      {
        stateProducts.length > 0 ? 
        <>
        {fileIsDownloaded && <img src={Logo} alt="McDonalds" className="logo" />}
        <div className="share-text">
          {!fileIsDownloaded ? 
          'We maken jouw Wishlist' :
          <>
            Je vindt de Wishlist in je downloads! Geef ‘m door in de McDrive en pik jouw bestelling zo snel mogelijk op. Smakelijk!
            <a className="btn btn-yellow" href="gmalite://gmalite-smartweb?weburl=https://menu.mcdonaldsnederland.app&apn=com.mcdonalds.mobileapp&isi=1217507712&ibi=com.mcdonalds.mobileapp">Terug naar de App</a>
          </>
          }
        </div> 
        <div className="wishlist-pdf wishlist">
          <div className="pdf-title wishlist-head">
            <h2>Jouw McDonald's Wishlist</h2>
            <p>Hier vind je jouw Wishlist. Geef ’m door in de McDrive en pik jouw <span>bestelling zo snel mogelijk op.</span></p>
            <img src={Logo} alt=""/>
          </div>
          <ul className="pdf-list wishlist-products-list">
            {
              stateProducts.map((item, key) => {
                return ( 
                  <li className="wishlist-product" key={key}>
                    <img src={imgPath(item.image)} alt="" className="product-image" onLoad={() => setImagesLoaded(prevState => prevState + 1)} />
                    <div className="product-info">
                      <div className="product-name">{item.name}</div>
                      <div className="product-options">
                        {
                          item.options.map((i, key) => {
                            if(key !== item.options.length - 1) {
                              return (
                                <span key={key}>{i},</span>
                              )
                            } else {
                              return (
                                <span key={key}>{i}</span>
                              )
                            }
                          })
                        }
                      </div>
                    </div>
                    <div className="product-amount">
                      {item.amount}
                    </div>
                  </li>
                )
              })
            }
            <li className="wishlist-note">
              <div className="note-text">
                <b>Voedingsinformatie</b>
                <p>Heb je speciale verzoeken of dieetwensen? Vermeld deze dan tijdens het bestellen.</p>
                <p>Meer weten over voedingswaarden en allergenen, check <strong>mcdonalds.nl/producten</strong></p>
              </div>
            </li>
          </ul>
          <div className="pdf-bottom">
            <span className="date">{date}</span>
            <span><img src={Logo} alt=""/>McDonald's Nederland B.V.</span>
          </div>
        </div>
        </>
        :
        <div className="loader" />
      }
    </section>
  )
}
