import React, { Component } from 'react';
import { BrowserRouter as Router, Route } from 'react-router-dom';

import Categories from './pages/Categories/Categories';
import ProductsList from './pages/ProductsList/ProductsList';
import Menus from './pages/Menus/Menus';
import ProductDetail from './pages/ProductDetail/ProductDetail';
import Tutorial from './pages/Tutorial/Tutorial';
import Wishlist from './pages/Wishlist/Wishlist';
import Share from './pages/Share/Share'

import ScrollToTop from './components/ScrollToTop/ScrollToTop';

import { getCategories, getProducts, getMenus } from './utils/_api-connector';
import store from './redux/store';
import { GET_CATEGORIES, GET_PRODUCTS, GET_MENUS } from './redux/actions';
import './App.scss';

class Main extends Component {
  componentDidMount() {
    getCategories().then(response => {
      const { data } = response;
      store.dispatch({
        type: GET_CATEGORIES,
        data: data
      });
    })
    getProducts().then(response => {
      const { data } = response;
      store.dispatch({
        type: GET_PRODUCTS,
        data: data
      });
    })
    getMenus().then(response => {
      const { data } = response;
      store.dispatch({
        type: GET_MENUS,
        data: data
      });
    })
  }
  render() {
    return (
      <div className="App">
        <Router>
          <ScrollToTop />
          <Route exact path="/" component={Categories} />
          <Route path="/tutorial" component={Tutorial} />
          <Route path="/products-list" component={ProductsList} />
          <Route path="/menus" component={Menus} />
          <Route path="/product-detail" component={ProductDetail} />
          <Route path="/wishlist" component={Wishlist} />
          <Route path="/share" component={Share} />
        </Router>
      </div>
    );
  }
}

export default Main;
