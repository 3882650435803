import React, { Component } from 'react';
import { imgPath } from '../../utils/_api-connector';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import './_ProductMainInfo.scss';

class ProductMainInfo extends Component {

  constructor() {
    super();
    this.state = {
      readMoreText: 'Lees meer',
      fullTextView: true,
      isLinkVisible: false
    }
  }

  componentDidMount() {
    this.countLines();
  }

  handleHiddenText = () => {
    this.setState(prevState => ({
      fullTextView: !prevState.fullTextView,
      readMoreText: !prevState.fullTextView ? 'Lees minder' : 'Lees meer'
    }))
  }

  countLines = () => {
    const el = document.querySelector('.product-detail-description'),
          elHeight = el.offsetHeight,
          elLineHeight = parseInt(document.defaultView.getComputedStyle(el, null).getPropertyValue('line-height')),
          linesAmount = elHeight / elLineHeight;
    if(linesAmount > 2) {
      this.setState({
        isLinkVisible: true,
        fullTextView: false
      })
    }
  }

  render() {
    const { image, name, description } = this.props;
    return (
      <div className="product-detail-info">
        <h2>{name}</h2>
        <picture className="product-detail-img">
          <LazyLoadImage effect="opacity" src={imgPath(image)} alt={name} />
        </picture>
        <div dangerouslySetInnerHTML={{__html: description}} className={`product-detail-description${this.state.fullTextView ? ' full' : ''}`}></div>
        {this.state.isLinkVisible && <span className="read-more" onClick={this.handleHiddenText}>{this.state.readMoreText}</span>}
      </div>
    );
  }
}

export default ProductMainInfo;
