//event listener for bridge ready
/* global mcd */
export const setPageTitle = (title) => {
  if (!window.mcd) return;
  const appBar = mcd.bridge.message('appBar');
  appBar.send({
    'appBarTitle': title,
    'appBarBackButtonName': 'Terug'
  });
  appBar.on('done', () => {
    console.log('appbar done');
  });
}

export const tagsToPlexure = (tags) => {
  if (process.env.NODE_ENV === 'development') return;

  return new Promise((resolve, reject) => {
    let user = mcd.bridge.message('user');

    user.send({"addTags": tags});
    user.on('data', function(data) {
      resolve(data);
    });
    user.on('error', function(error) {
      reject(error);
    });
    user.on('done', function() {
      // no more event will be emitted
      console.log("add/remove tags done");
    });
  });
}