import React from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { imgPath } from '../../utils/_api-connector';
import { setPageTitle } from '../../utils/_mcd-connector';
import { tagsToPlexure } from '../../utils/_mcd-connector';
import BackToWishlist from '../../components/BackToWishlist/BackToWishlist';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/opacity.css';
import './_Categories.scss';


class Categories extends React.PureComponent {

  componentDidMount() {
    tagsToPlexure(['McDNL_MDB_']);
    setPageTitle('Wishlist')
    if(new URLSearchParams(window.location.href).get('items')) {
      this.props.history.push({pathname: '/share', state: {items: new URLSearchParams(window.location.href).get('items')}})
    }

    window.dataLayer = window.dataLayer || [];

    window.dataLayer.push({
      event: 'page_view',
      eventProperties: {
        page_virtual_path: '/wishlist',
        page_type: 'wishlist'
      }
    });
  }

  onButtonClick () {
    window.dataLayer = window.dataLayer || [];

    window.dataLayer.push({
      event: 'button_click',
      eventAction: 'meer weten',
      eventLabel: '/wishlist/tutorial'
    });
  }

  onListClick (name) {
    window.dataLayer = window.dataLayer || [];

    window.dataLayer.push({
      event: 'button_click',
      eventAction: name,
      eventLabel: `/wishlist/${name}`
    });
  }
  
  render() {
    const wishlistAmount = localStorage.getItem('orderItems') && JSON.parse(localStorage.getItem('orderItems')).length;
    return (
      <div className="categories">
        <Link to="/tutorial" className="intro-banner">
          <div className="banner-info">
            <h2>Vul alvast jouw</h2>
            <h1>Wishlist</h1>
          </div>
          <button onClick={this.onButtonClick} className="btn btn-white">Meer weten</button>
        </Link>
        {
          this.props.categories.list.length > 0 ?
          <ul className="categories-list">
            {
              this.props.categories.list.map((el, item) => {
                const name = el.name;
                const pageRedirect = el.id === 4 || el.id === 7 ? '/menus' : '/products-list';
                return (
                  <li onClick={this.onListClick.bind(this, name)} key={item} className="category-item">
                    <Link to={{pathname: pageRedirect, search:`?category=${el.id}`}} className="category-link">
                      <picture className="category-img">
                        <LazyLoadImage effect="opacity" src={imgPath(el.image)} alt={el.name} />
                      </picture>
                      <span className="category-name">{name}</span>
                    </Link>
                  </li>
                )
              })
            }
          </ul>
          :
          <div className="loader"></div>
        }
        {wishlistAmount > 0 && <BackToWishlist /> }
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return { 
    categories: state.categories
  };
}

export default connect(mapStateToProps)(Categories);
