import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { imgPath } from '../../utils/_api-connector';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import './_ProductItem.scss';

class ProductItem extends Component {

  onItemClick () {
    window.dataLayer = window.dataLayer || [];
    const label = this.props.name.toLocaleLowerCase().replaceAll(' ', '-');
    const page = this.props.pageName.toLocaleLowerCase().replaceAll(' ', '-');

    window.dataLayer.push({
      event: 'button_click',
      eventAction: this.props.name,
      eventLabel: `/wishlist/${page}/${label}`
    });
  }

  render() {
    return (
      <li onClick={this.onItemClick.bind(this)} className="product-item">
        <Link to={{pathname: '/product-detail', search:`?productId=${this.props.id}`}} className="product-item-link">
          <picture className="product-item-img">
            <LazyLoadImage effect="opacity" src={imgPath(this.props.image)} alt={this.props.name} />
          </picture>
          <div className="product-item-name">{this.props.name}</div>
        </Link>
      </li>
    );
  }
}


export default ProductItem;
