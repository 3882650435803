import React, { Component } from 'react';
import scrollToElement from 'scroll-to-element';
import './_AccordionItem.scss';

class AccordionItem extends Component {
  constructor() {
    super();
    this.accordionItem = React.createRef();
  }
  toggleOpen = (e) => {
    let target = this.accordionItem.current;
    if(target.classList.contains('open')) {
      target.classList.remove('open');
    } else {
      target.classList.add('open');
      scrollToElement(target, {
        duration: 500
      });
    }
  }
  render() {
    return (
      <div className='accordion-item' ref={this.accordionItem}>
        <div className="accordion-toggle" onClick={this.toggleOpen}>
          {this.props.title}
        </div>
        <div className='accordion-content'>
          {this.props.children}
        </div>
      </div>
    );
  }
}

export default AccordionItem;
