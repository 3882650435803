import React from 'react';
import { Link } from 'react-router-dom';
import { setPageTitle } from '../../utils/_mcd-connector';
import AccordionItem from '../../components/AccordionItem/AccordionItem';
import Tutorial1 from '../../assets/tutorial-1.svg';
import Tutorial2 from '../../assets/tutorial-2.svg';
import Tutorial3 from '../../assets/tutorial-3.svg';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import './_Tutorial.scss';

class Tutorial extends React.PureComponent {
  componentDidMount() {
    setPageTitle('Hoe werkt de Wishlist?');
    if(this.props.location.state && this.props.location.state.faq) {
      window.scrollTo({
        top: document.querySelector('.faq').getBoundingClientRect().top + window.scrollY,
        left: 0,
        behavior: 'auto'
      })
    }
  }
  faqContent = () => {
    const contentList = [
      {
        title: 'Waarom lanceert McDonald’s een Wishlist in de App?',
        content: 'We lanceren de Wishlist om het bestellen bij McDonald’s nog makkelijker te maken voor onze gasten. Met de Wishlist kan je waar je ook bent het menu bekijken en je bestelling gemakkelijk samenstellen.  Op deze manier kan je nog sneller jouw bestelling doorgeven en geniet je zo snel mogelijk van je favoriete McDonald’s producten!'
      },
      {
        title: 'Hoe werkt de Wishlist?',
        content: 'Met de Wishlist kan je van te voren gemakkelijk je bestelling samenstellen. Zo kun je nog sneller jouw bestelling doorgeven en sta je super snel bij het raam om ‘m op te halen. Met de Wishlist ben je dus altijd bestel ready!'
      },
      {
        title: 'Wat heb ik als gast aan de Wishlist?',
        content: 'Met de Wishlist kan je voorafgaand aan je bezoek aan ons restaurant je bestelling gemakkelijk samenstellen. Je hebt dan alle tijd om rustig het menu te bekijken en hoef je bij het restaurant of in de McDrive alleen nog maar je bestelling op te lezen. Zo ben je helemaal bestel ready en kan je zo snel mogelijk genieten van jouw favoriete McDonald’s producten.'
      },
      {
        title: 'Kan ik mijn bestelling ook direct plaatsen via de App?',
        content: 'Nee, dit is niet mogelijk. Via de Wishlist kan je je bestelling alvast samenstellen, zodat je deze bij het bestellen alleen nog maar hoeft op te lezen. Dus op dit moment kan je nog geen bestelling plaatsen via de App. We zijn altijd bezig met het verbeteren en uitbreiden van onze App, dus houd hem in de gaten!'
      },
      {
        title: 'Waarom staan er geen prijzen bij de producten?',
        content: 'De prijzen kunnen per restaurant verschillen, deze worden getoond op de menuborden in de McDrive of in het restaurant. Wanneer je je bestelling doorgeeft verschijnen de prijzen van je gekozen producten op het kleine scherm in de praatpaal of op de kassa. Je kunt je bestelling bij het doorgeven altijd nog wijzigen.'
      },
      {
        title: 'Hoe sla ik mijn Wishlist op?',
        content: 'Je Wishlist wordt automatisch bewaard in de App als je producten toevoegt, ook als je de App tussendoor afsluit. Op die manier kan je gemakkelijk terug naar je bestelling. De Wishlist kan ook weer verwijderd worden bij het overzicht van jouw bestelling. Zo kan je direct weer een nieuwe lijst beginnen!'
      },
      {
        title: 'Kan ik meerdere lijstjes opslaan in de Wishlist?',
        content: 'Helaas is dit (nog) niet mogelijk. Je kunt op dit moment een bestellijst bewaren in de App.'
      },
      {
        title: 'Kan ik mijn dieetwensen of speciale verzoeken ook toevoegen aan de Wishlist?',
        content: 'Het is niet mogelijk om dit toe te voegen aan de Wishlist. Geef dieetwensen of speciale verzoeken direct door tijdens het bestellen, dan wordt hier rekening mee gehouden tijdens de bereiding.'
      },
      {
        title: 'Waar vind ik de voedingsinformatie?',
        content: 'De voedings- en allergenen informatie is terug te vinden bij de individuele producten binnen ons assortiment in de App, of op de McDonald’s website.'
      },
      {
        title: 'Kan ik mijn bestelling vanuit de Wishlist laten scannen?',
        content: 'Helaas is dit (nog) niet mogelijk, maar er wordt achter de schermen hard gewerkt aan verbeteringen dus houd de App in de gaten.'
      },
      {
        title: 'Kan ik ook McDelivery bestellen via de App?',
        content: 'Helaas is dit niet mogelijk. Een McDelivery bestelling doen gaat via de kanalen van onze partners Uber Eats of Thuisbezorgd. Kijk voor meer informatie over McDelivery op <a href="https://www.mcdonalds.com/nl/nl-nl/services/mcdelivery.html">https://www.mcdonalds.com/nl/nl-nl/services/mcdelivery.html</a>'
      },
      {
        title: 'Werkt de Wishlist op alle telefoons?',
        content: 'De Wishlist werkt op iedere telefoon met de McDonald’s App, zowel Android als iOS. Het is niet nodig om je te registreren om gebruik te kunnen maken van de Wishlist, deze is ook beschikbaar zonder account.'
      },
      {
        title: 'Kan ik de Wishlist ook in het buitenland gebruiken?',
        content: 'Nee, dit kan niet. Ieder land biedt zijn eigen unieke McDonald’s menu aan en in de Wishlist vind je alleen het Nederlandse menu. Daarom kan je de Wishlist vooralsnog alleen in Nederland gebruiken.'
      },
      {
        title: 'Kan ik mijn Wishlist met anderen delen?',
        content: 'Ja, dit is mogelijk! Nadat je jouw bestelling hebt samengesteld in de App, kan je bij jouw Wishlist klikken op ‘Deel Wishlist’. Dan wordt de Wishlist automatisch gedownload op je telefoon, zodat je hem gemakkelijk met je familie of vrienden kan delen!'
      },
      {
        title: 'Heb je nog vragen?',
        content: `Heb je na het lezen van deze informatie nog vragen? Neem dan contact met ons op via <a href="https://www.mcdonalds.nl/contactgegevens?openOutsideMcd=true">https://www.mcdonalds.nl/contactgegevens</a>. Veel plezier met jouw Wishlist en eet smakelijk!`
      }
    ]
    return contentList.map((item, key) => {
      return (
        <AccordionItem title={item.title} key={key} img={item.image} faq className="">
          <div dangerouslySetInnerHTML={{ __html: item.content}} />
        </AccordionItem>
      )
    })
  }
  renderSteps = () => {
    const stepsContent = [
      {
        title: 'Welkom bij McDonald’s',
        content: 'Ook zo’n zin in McDonald’s? Je kunt nu contactloos je bestelling bij ons ophalen in de McDrive en het restaurant! Tip: kom op een rustig moment, zo hoef je niet lang te wachten.',
        image: Tutorial1
      },
      {
        title: 'Kom bestel ready met de Wishlist',
        content: 'Stel van te voren jouw bestelling samen in de wishlist. Zo ben je altijd bestel ready in de McDrive, aan de counter of bij de Kiosk.',
        image: Tutorial2
      },
      {
        title: 'Zo snel mogelijk genieten van jouw favorieten',
        content: 'Met de ultieme voorbereiding: jij hebt je bestelling al klaar. Zo kun je nog sneller jouw bestelling doorgeven en ophalen. Eet smakelijk!',
        image: Tutorial3
      }
    ]
    return stepsContent.map((i, key) => {
      return (
        <li key={key}>
          <div className="tutorial-step">
            <div className="step-title">
              {i.title}
            </div>
            <div className="step-info">
              {i.content}
            </div>
            <div className="step-image">
              <LazyLoadImage effect="opacity" src={i.image} alt="" />
            </div>
          </div>
        </li>
      )
    })
  }
  render() {
    return (
      <div className="tutorial">
        <ol className="tutorial-list">
          {this.renderSteps()}
        </ol>
        <div className="faq">
          <div className="faq-title">Veelgestelde vragen</div>
          {this.faqContent()}
        </div>
        <div className="proceed-btn">
          <Link to="/" className="btn btn-yellow">Maak je Wishlist!</Link>
        </div>
      </div>
    );
  }
}

export default Tutorial;
