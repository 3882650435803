/*
 * action types
 */

export const GET_CATEGORIES = 'GET_CATEGORIES';
export const GET_MENUS = 'GET_MENUS';
export const GET_PRODUCTS = 'GET_PRODUCTS';

/*
 * action creators
 */

export function getCategories(data) {
  return { type: GET_CATEGORIES, data }
}

export function getMenus(data) {
  return { type: GET_MENUS, data }
}

export function getProducts(data) {
  return { type: GET_PRODUCTS, data }
}