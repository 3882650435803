import React from 'react';
import { connect } from 'react-redux';
import CategoryDescription from '../../components/CategoryDescription/CategoryDescription';
import ProductItem from '../../components/ProductItem/ProductItem';
import BackToWishlist from '../../components/BackToWishlist/BackToWishlist';
import './_Menus.scss';

class Menus extends React.PureComponent {
  constructor() {
    super();
    this.state = {
      pageName: '',
      productsList: []
    }
  }

  static getDerivedStateFromProps(nextProps, prevState){
    const categoryId = +new URLSearchParams(nextProps.location.search).get('category');
    const productsList = nextProps.menus.list.filter(el => {
      if(el.category_id === categoryId) {
        return el;
      }
      return false;
    });
    return { productsList };
  }

  componentDidMount() {
    if(document.querySelector('.fake-photo')) {
      let fakePhoto = document.querySelector('.fake-photo');
      document.querySelector('.whishlist-pocket').classList.add('animated');
      fakePhoto.classList.add('animated');

      fakePhoto.addEventListener('animationend', () => {
        fakePhoto.remove()
      })

      document.querySelector('.whishlist-pocket').classList.add('animated');
    }
  }

  render() {
    const categoryId = +new URLSearchParams(this.props.location.search).get('category'),
          wishlistAmount = localStorage.getItem('orderItems') && JSON.parse(localStorage.getItem('orderItems')).length;
    return (
      <div className="products-section">
        <CategoryDescription categoryId={categoryId} setPageName={(name) => this.setState({ pageName: name })} />
        {
          this.state.productsList.length > 0 ?
          <ul className="products-list">
            {this.state.productsList.map((el, item) => {
              return (
                <ProductItem 
                  key={item}
                  id={el.id}
                  name={el.name}
                  image={el.image}
                  pageName={this.state.pageName}
                />)
            })}
          </ul>
          :
          <div className="loader"></div>
        }
        {wishlistAmount > 0 && <BackToWishlist /> }
      </div>
    );
  }
}


const mapStateToProps = (state) => {
  return { 
    menus: state.menus
  };
}

export default connect(mapStateToProps)(Menus);
