import React from 'react';
import './_RemoveModal.scss';

export default (props) => {
  return (
    <section className="modal">
      <div className="modal-content">
        <h2>Wishlist verwijderen?</h2>
        <div className="modal-actions">
          <button className="btn btn-transparent" onClick={props.closeModal}>Annuleren</button>
          <button className="btn btn-yellow" onClick={props.removeWishlist}>Verwijderen</button>
        </div>
      </div>
      <div className="modal-background" onClick={props.closeModal}></div>
    </section>
  )
}